<div
  class="
    d-flex
    flex-column
    vh-100
    justify-content-center
    align-items-center
    pointer
    background
  "
>
  <h1 (click)="surprise()">Will and Tom's Wedding</h1>
  <img
    (load)="loading = false"
    class="my-4 shadow"
    src="assets/general-images/landing.jpg"
  />
  <div class="d-flex">
    <button routerLink="/host/create-lobby" class="mx-3 btn btn-primary btn-lg">
      Create Lobby
    </button>
    <button
      routerLink="/players/join-lobby"
      class="mx-3 btn btn-primary btn-lg"
    >
      Join Lobby
    </button>
  </div>
</div>

<app-loader *ngIf="loading" [background]="true"></app-loader>
