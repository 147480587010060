import { Component, OnInit } from '@angular/core';
import confetti from 'canvas-confetti';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  loading = true;
  cheerSound: any;
  constructor() {}

  public surprise(): void {
    // Play a random sound (from marioCheer1 to marioCheer5) when a new player is added
    this.cheerSound = new Audio();
    const randomInteger = Math.floor(Math.random() * 5) + 1; // there are 5 possible cheerSound sounds
    this.cheerSound.src = 'assets/sounds/marioCheer' + randomInteger + '.wav';
    this.cheerSound.play();
    this.bigConfetti();
  }

  bigConfetti(): void {
    var count = 200;
    var defaults = {
      origin: { y: 0.3 },
      ticks: 400,
    };

    function fire(particleRatio, opts) {
      confetti(
        Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio),
        })
      );
    }

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }

  ngOnInit(): void {
    this.bigConfetti();
  }
}
